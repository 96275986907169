// Vendors
import React from 'react';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import { AssetComponent, ButtonComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  REQUEST_SIGNATURE_KEY_CONFIRMATION_ASSET_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_BUTTON_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_01_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_02_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_03_PROPS,
} from './constants/request-signature-key-confirmation.constants';
// Hooks
import RequestSignatureKeyConfirmationHook from './hooks/request-signature-key-confirmation.hook';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import { RequestSignatureKeyConfirmationStyled } from './request-signature-key-confirmation.component.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
} from './translations/request-signature-key-confirmation.translations';
import translations from '../../translations/request-signature-key.translations';
// Utils
import { createPostalAddress } from '../step1/utils/request-signature-key-step1.utils';

const RequestSignatureKeyConfirmationComponent = (): React.ReactElement => {
  const { address, dateRequested, formatDate, formatMessage, handleGoToDashboardButtonClick } =
    RequestSignatureKeyConfirmationHook();

  return (
    <AppCardSectionComponentStyled>
      <AppCardContentSectionComponentStyled>
        <RequestSignatureKeyConfirmationStyled>
          <AssetComponent {...REQUEST_SIGNATURE_KEY_CONFIRMATION_ASSET_PROPS} />
          <ParagraphComponent {...REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_01_PROPS}>
            <FormattedMessageComponent
              id={DESCRIPTION_01}
              values={{
                requestDate: formatDate(
                  dateRequested,
                  MiscellaneousDateConstants.FORMAT_DD_MM_YYYY
                ),
              }}
            />
          </ParagraphComponent>
          {address && (
            <ParagraphComponent {...REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_02_PROPS}>
              {createPostalAddress({ address, formatMessage }).map((item, index) => (
                <React.Fragment key={`${item}-${index}`}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </ParagraphComponent>
          )}
          <ParagraphComponent {...REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_03_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION_02} />
          </ParagraphComponent>
          <ButtonComponent
            {...REQUEST_SIGNATURE_KEY_CONFIRMATION_BUTTON_PROPS}
            onClick={handleGoToDashboardButtonClick}
          >
            <FormattedMessageComponent id={translations.GO_TO_DASHBOARD} />
          </ButtonComponent>
        </RequestSignatureKeyConfirmationStyled>
      </AppCardContentSectionComponentStyled>
    </AppCardSectionComponentStyled>
  );
};

export default RequestSignatureKeyConfirmationComponent;
