// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Translations
import { CLIENT_NAME } from 'containers/recovery-key/translations/recovery-key.translations.cc';

const ClientName: React.FC<{ firstLastName?: string }> = ({ firstLastName = '' }) => (
  <ParagraphComponent bold>
    <FormattedMessageComponent id={CLIENT_NAME} values={{ firstLastName }} />
  </ParagraphComponent>
);

export { ClientName };
