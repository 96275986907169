// Enumeration
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';

const dontHaveRecoveryOptionsOrHasDirect = (
  passwordRecoveryOptions: PasswordRecoveryOptionsEnumeration[]
) =>
  passwordRecoveryOptions?.length === 0 ||
  passwordRecoveryOptions.includes(PasswordRecoveryOptionsEnumeration.DIRECT);

const getNextRecoveryKeyStep = (
  passwordRecoveryOptions: PasswordRecoveryOptionsEnumeration[]
): WizardStepsEnumeration =>
  dontHaveRecoveryOptionsOrHasDirect(passwordRecoveryOptions)
    ? WizardStepsEnumeration.FIVE
    : WizardStepsEnumeration.THREE;

export { getNextRecoveryKeyStep };
