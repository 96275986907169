// Contexts
import {
  useRecoveryKeyContextConsumerHook,
  useRecoveryOptionContextConsumerHook,
} from 'containers/recovery-key/contexts/recovery-key.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import RecoveryKeyStep1ActionsHandlers from '../handlers/recovery-key-step1-actions.handlers';
// Types
import { RecoveryKeyStep1ActionsHookType } from './types/recovery-key-step1-actions.hook.type';

const useRecoveryKeyStep1ActionsHook = (): RecoveryKeyStep1ActionsHookType => {
  const { email, prefix, phoneNumber, fetching, setFetching } = useRecoveryKeyContextConsumerHook(),
    { recoveryOption } = useRecoveryOptionContextConsumerHook(),
    { setAuthData } = useRecoveryKeyContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook();

  return {
    ...RecoveryKeyStep1ActionsHandlers({
      email,
      prefix,
      phoneNumber,
      setAuthData,
      setCurrentStep,
      setFetching,
      setResult,
    }),
    email,
    recoveryOption,
    prefix,
    phoneNumber,
    fetching,
  };
};

export { useRecoveryKeyStep1ActionsHook };
