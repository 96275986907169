// Vendors
import React from 'react';
// Components
import {
  ConstraintsComponent,
  LinkComponent,
  ParagraphComponent,
  SecurityModuleComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import SecurityKeysSignatureKeyChangeSecurityKeyPasswordModuleAssetComponent from './components/asset/security-keys-signature-key-change-second-step-security-key-asset.component';
// Constants
import constants from './constants/security-keys-signature-key-change-second-step-security-key.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  PasswordModuleComponentStyled,
  PasswordModuleAssetWrapperStyled,
  PasswordModuleRightComponentStyled,
} from './security-keys-signature-key-change-second-step-security-key.component.styled';
// Translations
import translations from '../../translations/security-keys-signature-key-change.translations';
// Types
import { PasswordModuleComponentType } from './types/security-keys-signature-key-change-second-step-security-key.component.type';
// Utils
import { getSignatureKeyConstraints } from '../../utils/security-keys-signature-key-change.constraints.util';

const SecurityKeysSignatureKeyChangeSecurityKeyComponent = ({
  analytics,
  assetId = constants.SECURITY_KEY_DEFAULT_ASSET_ID,
  cellsAmount = constants.SECURITY_KEY_DEFAULT_GAPS,
  description,
  disabled,
  constrainsEnabled = constants.SECURITY_KEY_DEFAULT_CONSTRAINS_ENABLED,
  failedValidationStatus,
  errorMessage,
  inverted,
  onClick,
  onChange,
  title,
  value,
  recoveryLink,
}: PasswordModuleComponentType): React.ReactElement<PasswordModuleComponentType> => {
  const { formatMessage } = useCbIntl();

  return (
    <PasswordModuleComponentStyled {...constants.WRAPPER_PROPS}>
      <PasswordModuleAssetWrapperStyled>
        <SecurityKeysSignatureKeyChangeSecurityKeyPasswordModuleAssetComponent {...{ assetId }} />
      </PasswordModuleAssetWrapperStyled>
      <PasswordModuleRightComponentStyled>
        <TitleComponent {...constants.SECURITY_KEY_TITLE_PROPS}>
          {formatMessage({ id: title })}
        </TitleComponent>
        {description && (
          <ParagraphComponent {...constants.SECURITY_KEY_DESCRIPTION_PROPS}>
            {formatMessage({ id: description })}
          </ParagraphComponent>
        )}
        {constrainsEnabled && (
          <ConstraintsComponent
            constraints={getSignatureKeyConstraints(failedValidationStatus ?? null, formatMessage)}
            value={value}
          />
        )}
        <SecurityModuleComponent
          translations={{
            SHOW_PASSWORD: formatMessage({ id: translations.SHOW_PASSWORD_TEXT }),
            HIDE_PASSWORD: formatMessage({ id: translations.HIDE_PASSWORD_TEXT }),
          }}
          {...{
            analytics,
            disabled,
            errorMessage: errorMessage && formatMessage({ id: errorMessage }),
            inverted,
            onClick,
            onChange,
            cellsAmount,
            value,
          }}
        />
        {recoveryLink && (
          <ParagraphComponent>
            <FormattedMessageComponent
              id={translations.RECOVERY_KEY_LABEL}
              bold={false}
              values={{
                link: (chunks: React.ReactNode) => (
                  <LinkComponent onClick={recoveryLink} type="inline_primary">
                    {chunks}
                  </LinkComponent>
                ),
              }}
            />
          </ParagraphComponent>
        )}
      </PasswordModuleRightComponentStyled>
    </PasswordModuleComponentStyled>
  );
};

export { SecurityKeysSignatureKeyChangeSecurityKeyComponent };

export default SecurityKeysSignatureKeyChangeSecurityKeyComponent;
