// Vendors
import React from 'react';
// Components
import { ParagraphComponent, LoaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';

// Styles
import { SecurityKeysAccessKeyChangeLoaderComponentCcStyled } from './security-keys-access-key-change-loader.component.styled.cc';
// Translations
import { WAIT_IN_IVR_DESCRIPTION } from './translations/security-keys-access-key-change-loader.translations.cc';

const SecurityKeysAccessKeyChangeLoaderComponentCc = (): React.ReactElement => {
  return (
    <SecurityKeysAccessKeyChangeLoaderComponentCcStyled>
      <LoaderComponent type="primary" />
      <ParagraphComponent>
        <FormattedMessageComponent id={WAIT_IN_IVR_DESCRIPTION} />
      </ParagraphComponent>
    </SecurityKeysAccessKeyChangeLoaderComponentCcStyled>
  );
};

export { SecurityKeysAccessKeyChangeLoaderComponentCc };
