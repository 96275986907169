// Vendors
import { useState } from 'react';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import SecurityKeysSignatureKeyChangeContentCCHandlers from '../handlers/security-keys-signature-key-change-footer-content.cc.handlers';
// Types
import { SignatureKeyFooterContentCCHookType } from './types/security-keys-signature-key-change-footer-content.cc.hook.type';

const SecurityKeysSignatureKeyChangeFooterContentCCHook =
  (): SignatureKeyFooterContentCCHookType => {
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const [isLoadingIvr, setIsLoadingIvr] = useState<boolean>(false);

    return {
      ...SecurityKeysSignatureKeyChangeContentCCHandlers({
        setIsLoadingIvr,
        setResult,
      }),
      isLoadingIvr,
    };
  };

export default SecurityKeysSignatureKeyChangeFooterContentCCHook;
