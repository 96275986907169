// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PARAMS_PROPS,
  RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PROPS,
} from './constants/recovery-key-step1-actions.service.constants';
// Types
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';
import { RecoveryKeyStep1ApiBuilderType } from '../types/recovery-key-step-1-api-builder.type';
// Utilities
import { getDeviceLoginParams } from '@openbank/cf-ui-framework';

const initRecoveryKeyService = async (
  props: RecoveryKeyStep1ApiBuilderType
): Promise<[ChallengeSssApiResponseType | Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PROPS,
    params: {
      ...RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PARAMS_PROPS,
      ...getDeviceLoginParams({}),
      ...props,
    },
  });

export { initRecoveryKeyService };
