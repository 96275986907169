// Resolvers
import { createRecoveryKeyResolver } from '../resolvers/recovery-key-step5-actions.resolver';
// Types
import {
  RecoveryKeyStep5ActionsBuilderHandlersType,
  RecoveryKeyStep5ActionsBuilderReturnHandlersType,
} from './types/recovery-key-step5-actions.handlers.type';

const createRecoveryKeyProcessButtonClickHandler = async ({
  progressId,
  recoveryKey: password,
  setFetching,
  setResult,
}: RecoveryKeyStep5ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [, error] = await createRecoveryKeyResolver({ password, progressId });

  setResult(!error);
};

const RecoveryKeyStep5ActionsHandlers = (
  props: RecoveryKeyStep5ActionsBuilderHandlersType
): RecoveryKeyStep5ActionsBuilderReturnHandlersType => ({
  handleCreateRecoveryKeyButtonClick: () => createRecoveryKeyProcessButtonClickHandler(props),
});

export default RecoveryKeyStep5ActionsHandlers;
