// Vendors
import React from 'react';
// Components
import { ParagraphComponent, LoaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import { SecurityKeysSignatureKeyChangeLoaderComponentCcStyled } from './security-keys-access-key-change-loader.component.styled.cc';
// Translations
import { WAIT_IN_IVR_DESCRIPTION } from './translations/security-keys-access-key-change-loader.translations.cc';

const SecurityKeysSignatureKeyChangeLoaderComponentCc = (): React.ReactElement => (
  <SecurityKeysSignatureKeyChangeLoaderComponentCcStyled>
    <LoaderComponent type="primary" />
    <ParagraphComponent>
      <FormattedMessageComponent id={WAIT_IN_IVR_DESCRIPTION} />
    </ParagraphComponent>
  </SecurityKeysSignatureKeyChangeLoaderComponentCcStyled>
);

export { SecurityKeysSignatureKeyChangeLoaderComponentCc };
