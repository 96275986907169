// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useRequestSignatureKeyContextConsumerHook } from '../contexts/request-signature-key.context';
// Handlers
import RequestSignatureKeyHandlers from '../handlers/request-signature-key.handlers';
// Types
import { RequestsignatureKeyHookType } from './types/request-signature-key.hook.type';

const useRequestSignatureKeyHook = (): RequestsignatureKeyHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const { setIsAlreadyRequested, setErrorResponse } = useRequestSignatureKeyContextConsumerHook();

  const { handleVerifySignatureKeyStatus } = RequestSignatureKeyHandlers({
    setErrorResponse,
    setFetching,
    setIsAlreadyRequested,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleVerifySignatureKeyStatus();
  }, []);

  return {
    fetching,
  };
};

export default useRequestSignatureKeyHook;
