// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
import SecurityKeysAccessKeyChangeSidebarComponent from './components/sidebar/security-keys-access-key-change-sidebar.component';
import SecurityKeysAccessKeyChangeyComponentsCcView from './components/cc/security-keys-access-key-change-cc.view.cc';
// Contexts
import { SecurityKeysAccessKeyChangeContextConsumerHOC } from './contexts/security-keys-access-key-change.context';
// Constants
import { ACCESS_KEYS_CHANGE_WIZARD_PROPS } from './constants/security-keys-access-key-change.configuration.constants';
// Hooks
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Styles
import {
  SecurityKeysAccessKeyChangeContainerStyled,
  SecurityKeysAccessKeyChangeContentContainerStyled,
  SecurityKeysAccessKeyChangeContentLeftComponentStyled,
  SecurityKeysAccessKeyGridContentRightComponentStyled,
} from './security-keys-access-key-change.view.styled';
// Utils
import { isCC } from 'utils/app.utils';
import { showSidebarAccessKey } from './utils/security-keys-access-key-change-constraints.util';

const SecurityKeysAccessKeyChangeView = (): React.ReactElement => {
  const { isHybrid } = MobileConnectorContextConsumerHook();

  return (
    <SecurityKeysAccessKeyChangeContainerStyled>
      <SecurityKeysAccessKeyChangeContentContainerStyled>
        <SecurityKeysAccessKeyChangeContentLeftComponentStyled>
          {isCC() ? (
            <SecurityKeysAccessKeyChangeyComponentsCcView />
          ) : (
            <WizardComponent {...ACCESS_KEYS_CHANGE_WIZARD_PROPS} />
          )}
        </SecurityKeysAccessKeyChangeContentLeftComponentStyled>
        {showSidebarAccessKey(isHybrid, isCC()) && (
          <SecurityKeysAccessKeyGridContentRightComponentStyled>
            <SecurityKeysAccessKeyChangeSidebarComponent />
          </SecurityKeysAccessKeyGridContentRightComponentStyled>
        )}
      </SecurityKeysAccessKeyChangeContentContainerStyled>
    </SecurityKeysAccessKeyChangeContainerStyled>
  );
};

export default SecurityKeysAccessKeyChangeContextConsumerHOC(SecurityKeysAccessKeyChangeView);
