// Vendors
import styled from 'styled-components';

const RecoveryKeyStep3ComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
RecoveryKeyStep3ComponentStyled.displayName = 'RecoveryKeyStep3ComponentStyled';

const RecoveryKeyStep3ComponentLinkStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
RecoveryKeyStep3ComponentLinkStyled.displayName = 'RecoveryKeyStep3ComponentLinkStyled';

export { RecoveryKeyStep3ComponentStyled, RecoveryKeyStep3ComponentLinkStyled };
