// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const REQUEST_SIGNATURE_KEY_SIDEBAR_PROPS = {
    testId: 'request-signature-key',
  },
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_01_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'request-signature-key-sidebar-description-title-01',
  },
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_01_PROPS = {
    size: paragraphConstants.SIZES.M,
    testId: 'request-signature-key-sidebar-description-01',
  },
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_02_PROPS = {
    size: paragraphConstants.SIZES.M,
    testId: 'request-signature-key-sidebar-description-02',
  },
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_02_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'request-signature-key-sidebar-description-title-02',
  };

export {
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_01_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_02_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_01_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_02_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_PROPS,
};
