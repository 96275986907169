// Services
import {
  validateRecoveryKeyDataOtpService,
  validateSignatureKeyService,
  initRecoveryKeyService,
} from '../services/recovery-key.services';
// Types
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
import { RecoveryKeyStepResolverBuilderType } from 'containers/recovery-key/types/recovery-key-step-resolver-builder.type';
import { VerifySignatureKeyRecoverApiResponseType } from 'types/challenge/verify-signature-key-recover-api-response.type';
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';
// Utilities
import { isLegitSSSChallengeResponse } from 'utils/challenge.utils';

const validateRecoveryKeyDataOtpResolver = async (
  props: RecoveryKeyStepResolverBuilderType
): Promise<[PasswordRecoveryOptionsApiResponseType] | [null, true]> => {
  const [response] = await validateRecoveryKeyDataOtpService(props);

  return response?.passwordRecoveryOptions
    ? [response as PasswordRecoveryOptionsApiResponseType]
    : [null, true];
};

const validateSignatureKeyResolver = async (
  props: RecoveryKeyStepResolverBuilderType
): Promise<[VerifySignatureKeyRecoverApiResponseType] | [null, true]> => {
  const [response] = await validateSignatureKeyService(props);

  return response?.progressId
    ? [response as VerifySignatureKeyRecoverApiResponseType]
    : [null, true];
};

const initRecoveryKeyResolver = async (props: {
  username: string;
  phoneNumber: string;
}): Promise<[ChallengeSssApiResponseType] | [null, true]> => {
  const [response] = await initRecoveryKeyService(props);

  return isLegitSSSChallengeResponse(response)
    ? [response as ChallengeSssApiResponseType]
    : [null, true];
};

export {
  validateSignatureKeyResolver,
  validateRecoveryKeyDataOtpResolver,
  initRecoveryKeyResolver,
};
