// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

const RECOVERY_KEY_LOGO_PROPS = {
    id: 'amazon-zinia-logo',
    sizes: {
      width: 272,
      height: 40,
    },
    type: assetConstants.TYPES.LOGO,
    testId: 'recovery-key-dr-picasso-header-logo',
  },
  TIMER_COUNTER = 10;
const ENDPOINTS = {
  VERIFY_SIGNATURE_KEY: 'cb-api-auth/password/recover/signature-key/verify',
  VERIFY_OTP: 'cb-api-auth/password/recover/otp/verify',
  OTP_INITIALIZATION: 'cb-api-auth/password/recover/init',
};

export { RECOVERY_KEY_LOGO_PROPS, TIMER_COUNTER, ENDPOINTS };
