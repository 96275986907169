// Vendors
import { Usd } from '@openbank/cf-ui-framework';
// Constants
import {
  IVR_INTERVAL_TIME,
  IVR_TIME_OUT,
  IVR_SUCCESS_RESULT,
} from './constants/security-keys-access-key-change-cc-footer-content.handlers.constants.cc';
// Types
import { SecurityKeysAccessKeyChangeCCFooterReturnHandlersType } from './types/security-keys-access-key-change-cc-footer-content.cc.handlers.return.type';
import { SecurityKeysAccessKeyChangeCcCancelClickHandlersType } from './types/security-keys-access-key-change-cc-footer-content.cc.handlers.type';
import { ChangeAccessKeyInIvrHandlerType } from './types/change-access-key-in-ivr.handlers.type';

const changeAccessKeyInIvr = ({
  setResult,
  setIsLoadingIvr,
}: ChangeAccessKeyInIvrHandlerType): void => {
  setIsLoadingIvr(true);

  Usd()?.changeKeyInIvr();

  const startTime = Date.now();

  const intervalId = setInterval(() => {
    if (Usd()?.getAccessKeyChanged()) {
      const IVRResult = Usd()?.getAccessKeyChanged();

      clearInterval(intervalId);
      setIsLoadingIvr(false);
      setResult(IVRResult === IVR_SUCCESS_RESULT);
    } else if (Date.now() - startTime > IVR_TIME_OUT) {
      clearInterval(intervalId);
      setIsLoadingIvr(false);
      setResult(false);
    }
  }, IVR_INTERVAL_TIME);
};

const SecurityKeysAccessKeyChangeCCFooterHandlers = ({
  setResult,
  setIsLoadingIvr,
}: SecurityKeysAccessKeyChangeCcCancelClickHandlersType): SecurityKeysAccessKeyChangeCCFooterReturnHandlersType => ({
  handleChangeAccessKeyInIvr: () => changeAccessKeyInIvr({ setResult, setIsLoadingIvr }),
});

export default SecurityKeysAccessKeyChangeCCFooterHandlers;
