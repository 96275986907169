// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { SecurityKeysAccessKeyChangeLoaderComponentCc } from '../loader/security-keys-access-key-change-loader.component.cc';
// Constants
import { ACCESS_KEY_CHANGE_RIGHT_BUTTON_TEST_ID } from './constants/security-keys-access-key-change.constants.cc';
// Translations
import { TRANSFER_IN_IVR_BUTTON_TITLE } from './translations/security-keys-access-key-change-footer.translations.cc';
// Hooks
import SecurityKeysAccessKeyChangeCCFooterContentHook from '../../hooks/security-keys-access-key-change-cc-footer-content.cc.hook';

const AccessKeyChangeWizardStepFooterComponent = (): React.ReactElement => {
  const { handleChangeAccessKeyInIvr, isLoadingIvr } =
    SecurityKeysAccessKeyChangeCCFooterContentHook();

  return (
    <>
      <WizardStepFooterComponent
        hasDefaultRightButton={false}
        rightButtonConfiguration={{
          testId: ACCESS_KEY_CHANGE_RIGHT_BUTTON_TEST_ID,
          onClick: handleChangeAccessKeyInIvr,
          children: <FormattedMessageComponent id={TRANSFER_IN_IVR_BUTTON_TITLE} />,
          disabled: isLoadingIvr,
          loading: isLoadingIvr,
        }}
      />
      {isLoadingIvr && <SecurityKeysAccessKeyChangeLoaderComponentCc />}
    </>
  );
};

export { AccessKeyChangeWizardStepFooterComponent };
