// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Styles
import { RecoveryKeySidebarComponentStyled } from './recovery-key-sidebar.component.styled';
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  TITLE,
} from './translations/recovery-key-sidebar.translations';

const RecoveryKeySidebarComponent = (): React.ReactElement => (
  <SidebarComponent title={<FormattedMessageComponent id={TITLE} />}>
    <RecoveryKeySidebarComponentStyled>
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION_02} />
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
    </RecoveryKeySidebarComponentStyled>
  </SidebarComponent>
);

export { RecoveryKeySidebarComponent };
