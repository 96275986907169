// Vendors
import React from 'react';
// Components
import {
  ChallengeSignatureKeyComponent,
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  RECOVERY_KEY_STEP_4_SIGNATURE_KEY_DESCRIPTION_PROPS,
  RECOVERY_KEY_STEP_4_SIGNATURE_KEY_TITLE_PROPS,
} from './constants/recovery-key-step4-signature-key.constants';
// Hooks
import { useRecoveryKey } from 'containers/recovery-key/hooks/recovery-key.hook';
// Styles
import { RecoveryKeyStep4SignatureKeyComponentStyled } from './recovery-key-step4-signature-key.component.styled';
// Translations
import { DESCRIPTION, TITLE, CHALLENGE_TRANSLATIONS } from './translations/recovery-key-step4-signature-key.translations';
// Utilities
import { translateLabels } from 'utils/translations.utils';
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
import { ChallengeWrapperTypesEnumeration } from 'components/challenge-wrapper/configurations/enumerations/challenge-wrapper-types.enumeration';

const RecoveryKeyStep4SignatureKeyComponent = (): React.ReactElement => {
  const {
    onChangeChallenge,
    handleSendChallenge,
    handleValidateChallenge,
    positions,
    challenged,
    value,
    formatMessage,
    fetching,
  } = useRecoveryKey({
    nextStep: WizardStepsEnumeration.FIVE,
    challenge: ChallengeWrapperTypesEnumeration.SIGNATURE_KEY,
  });

  return (
    <RecoveryKeyStep4SignatureKeyComponentStyled>
      <TitleHeaderComponent {...RECOVERY_KEY_STEP_4_SIGNATURE_KEY_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </TitleHeaderComponent>
      <ParagraphComponent {...RECOVERY_KEY_STEP_4_SIGNATURE_KEY_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <ChallengeSignatureKeyComponent
        {...{ fetching, positions, value }}
        disabled={fetching || challenged}
        handlers={{ changeHandler: onChangeChallenge, buttonHandler: handleSendChallenge }}
        translations={translateLabels({ formatMessage, translations: CHALLENGE_TRANSLATIONS })}
      />
      <WizardStepFooterComponent
        isFooterDisabled={fetching}
        rightButtonConfiguration={{
          onClick: handleValidateChallenge,
          disabled: !challenged || fetching,
          loading: fetching,
        }}
      />
    </RecoveryKeyStep4SignatureKeyComponentStyled>
  );
};

export default RecoveryKeyStep4SignatureKeyComponent;
