const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.step1.';

const TITLE = `${DOMAIN}title`,
  MESSAGE_01 = `${DOMAIN}message01`,
  MESSAGE_02 = `${DOMAIN}message02`,
  MESSAGE_03 = `${DOMAIN}message03`,
  ADDRESS_BOX_TITLE = `${DOMAIN}addressBox.title`,
  TRUSTED_TITLE = `${DOMAIN}trustedTitle`,
  TRUSTED_DESCRIPTION = `${DOMAIN}trustedDescription`;

export {
  ADDRESS_BOX_TITLE,
  MESSAGE_01,
  MESSAGE_02,
  MESSAGE_03,
  TITLE,
  TRUSTED_DESCRIPTION,
  TRUSTED_TITLE,
};
