// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useRequestSignatureKeyContextConsumerHook } from 'containers/security-keys/views/request-signature-key/contexts/request-signature-key.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Types
import { RequestSignatureKeyHookType } from './types/request-signature-key-step1.hook.type';
// Handlers
import { RequestSignatureKeyStep1Handlers } from '../handlers/request-signature-key-step1.handlers';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';

const RequestSignatureKeyStep1Hook = (): RequestSignatureKeyHookType => {
  const { setFetching } =
    useRequestSignatureKeyContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { formatMessage } = useCbIntl();
  const { hasTrustedDevice } = useRequestSignatureKeyContextConsumerHook();
  const { profile } = AppContextConsumerHook(),
    address = profile?.addressDetails?.[0] || {};
  
  return { 
    ...RequestSignatureKeyStep1Handlers({
      setCurrentStep,
      setFetching,
      setResult
    }), 
    address,
    formatMessage,
    hasTrustedDevice,
  };
};

export { RequestSignatureKeyStep1Hook };
