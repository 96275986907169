// Vendors
import React from 'react';
// Components
import { ParagraphComponent, MessageComponent, ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestSignatureKeyStep2ChallengeComponent from './components/challenge/request-signature-key-step2-challenge.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/request-signature-key-step2.constants';
// Hooks
import useRequestSignatureKeyStep2Hook from './hooks/request-signature-key-step2.hook';
// Translations
import {
  MESSAGE_01,
  MESSAGE_02,
  MESSAGE_03,
  REQUEST_BUTTON,
} from './translations/request-signature-key-step2.translations';
// Styles
import { RequestSignatureKeyStep2ComponentStyled } from './request-signature-key-step2.component.styled';

const RequestSignatureKeyStep2Component = (): React.ReactElement => {
  const { challenged, handleRequestSignatureKeyButtonClick } = useRequestSignatureKeyStep2Hook();

  return (
    <RequestSignatureKeyStep2ComponentStyled>
      <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_01_PROPS}>
        <FormattedMessageComponent id={MESSAGE_01} />
      </ParagraphComponent>
      <MessageComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_PROPS}>
        <ResumeItemComponent
          {...constants.REQUEST_SIGNATURE_KEY_STEP_2_REQUEST_RESUME_ITEM_PROPS}
          label={<FormattedMessageComponent id={MESSAGE_02} />}
          value={<FormattedMessageComponent id={MESSAGE_03} />}
        />
      </MessageComponent>
      <RequestSignatureKeyStep2ChallengeComponent />
      <WizardStepFooterComponent
        rightButtonConfiguration={{
          onClick: handleRequestSignatureKeyButtonClick,
          disabled: challenged,
          children: <FormattedMessageComponent id={REQUEST_BUTTON} />,
        }}
      />
    </RequestSignatureKeyStep2ComponentStyled>
  );
};

export default RequestSignatureKeyStep2Component;
