// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { SecurityKeysSignatureKeyChangeLoaderComponentCc } from '../loader/security-keys-signature-key-change-loader.component.cc';
// Constants
import { SIGNATURE_KEY_CHANGE_RIGHT_BUTTON_TEST_ID } from './constants/security-keys-signature-key-change-footer.constants';
// Translations
import { TRANSFER_IN_IVR_BUTTON_TITLE } from './translations/security-keys-signature-key-change-footer.translations.cc';
// Hooks
import SecurityKeysSignatureKeyChangeFooterContentCCHook from '../../hooks/security-keys-signature-key-change-footer-content.cc.hook';

const SignatureKeyChangeWizardStepFooterComponent = (): React.ReactElement => {
  const { handleChangeSignatureKeyInIvr, isLoadingIvr } =
    SecurityKeysSignatureKeyChangeFooterContentCCHook();

  return (
    <>
      <WizardStepFooterComponent
        hasDefaultRightButton={false}
        rightButtonConfiguration={{
          testId: SIGNATURE_KEY_CHANGE_RIGHT_BUTTON_TEST_ID,
          onClick: handleChangeSignatureKeyInIvr,
          children: <FormattedMessageComponent id={TRANSFER_IN_IVR_BUTTON_TITLE} />,
          disabled: isLoadingIvr,
          loading: isLoadingIvr,
        }}
      />
      {isLoadingIvr && <SecurityKeysSignatureKeyChangeLoaderComponentCc />}
    </>
  );
};

export { SignatureKeyChangeWizardStepFooterComponent };
