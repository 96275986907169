// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const RecoveryKeyContainerStyled = styled.main`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  height: 100vh;
  position: relative;

  ${mobileMediaQuery} {
    height: auto;
  }
`;
RecoveryKeyContainerStyled.displayName = 'RecoveryKeyContainerStyled';

const RecoveryKeyHeaderContainerStyled = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  display: flex;
  justify-content: space-between;
  min-height: ${convertPxsToRems({ pixels: 64, base: 14 })};
  padding: 0 ${({ theme }) => theme.spaces.spacingS} 0 ${({ theme }) => theme.spaces.spacingL};
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};

    button {
      width: fit-content;
    }
  }
`;
RecoveryKeyHeaderContainerStyled.displayName = 'RecoveryKeyHeaderContainerStyled';

const RecoveryKeyContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxl};
  max-width: ${convertPxsToRems({ pixels: 1200, base: 14 })};
  height: 100%;
  padding: 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0 auto ${({ theme }) => theme.spaces.spacingXxxl};
  width: 100%;

  ${mobileMediaQuery} {
    padding: 0;
  }
`;
RecoveryKeyContentContainerStyled.displayName = 'RecoveryKeyContentContainerStyled';

export {
  RecoveryKeyContainerStyled,
  RecoveryKeyHeaderContainerStyled,
  RecoveryKeyContentContainerStyled,
};
