// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  ScriptBoxComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import { SignatureKeyChangeWizardStepFooterComponent } from './components/footer/security-keys-signature-key-change-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
import SecurityKeysSignatureKeyChangeCcConstraintsList from './components/security-keys-signature-key-change-constraints.cc';
import SecurityKeysSignatureKeyChangeCcFinalText from './components/security-keys-signature-key-change-final-text-cc';
// Constants
import {
  SIGNATURE_KEY_CHANGE_CONSTANTS_CC,
  SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS,
} from '../../constants/security-keys-signature-key-change.constants.cc';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import SecurityKeysSignatureKeyChangeFooterContentCCHook from './hooks/security-keys-signature-key-change-footer-content.cc.hook';
// Styles
import {
  SignatureKeyContainerCcStyled,
  SignatureKeyContainerCcSeparatorStyled,
  SignatureKeyContainerTextCcStyled,
} from './security-keys-signature-key-change.content.styled.cc';
// Translations
import {
  CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT,
  CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT,
  CHANGE_SIGNATURE_KEY_CC_TITLE,
} from '../../translations/security-keys-signature-key-change-card.translations.cc';

const SecurityKeysSignatureKeyChangeContentCC = (): React.ReactElement => {
  const { profile } = AppContextConsumerHook();
  const { handleChangeSignatureKeyInIvr } = SecurityKeysSignatureKeyChangeFooterContentCCHook();
  return (
    <WizardFeedbackComponent
      {...SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS}
      errorConfiguration={{
        ...SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS.errorConfiguration,
        buttonClick: handleChangeSignatureKeyInIvr,
      }}
    >
      <SignatureKeyContainerCcStyled data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.containerId}>
        <TitleHeaderComponent testId={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.titleId}>
          <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_TITLE} />
        </TitleHeaderComponent>
        <SignatureKeyContainerCcSeparatorStyled>
          <ScriptBoxComponent>
            <SignatureKeyContainerTextCcStyled>
              <ParagraphComponent bold>
                <FormattedMessageComponent
                  id={CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT}
                  values={{ lastName: profile?.userDetails?.firstSurname }}
                />
              </ParagraphComponent>
              <ParagraphComponent testId={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.firstTextId}>
                <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT} />
              </ParagraphComponent>
              <ParagraphComponent testId={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.secondTextId}>
                <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT} />
              </ParagraphComponent>
              <SecurityKeysSignatureKeyChangeCcConstraintsList />
              <SecurityKeysSignatureKeyChangeCcFinalText />
            </SignatureKeyContainerTextCcStyled>
          </ScriptBoxComponent>
        </SignatureKeyContainerCcSeparatorStyled>
        <SignatureKeyChangeWizardStepFooterComponent />
      </SignatureKeyContainerCcStyled>
    </WizardFeedbackComponent>
  );
};

export default SecurityKeysSignatureKeyChangeContentCC;
