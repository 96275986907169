// Vendors
import React from 'react';
// Components
import SecurityKeysAccessKeyChangeCcConstraintsList from './components/security-keys-access-key-change-cc-constraints.cc';
import SecurityKeysAccessKeyChangeComponentCcComponentText from './components/security-keys-access-key-change-cc-final-text-cc';
import {
  ParagraphComponent,
  ScriptBoxComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
import { AccessKeyChangeWizardStepFooterComponent } from './components/footer/security-keys-access-key-change-footer.component.cc';
// Constants
import {
  ACCESS_KEY_CHANGE_CONSTANTS_CC,
  ACCESS_KEY_CHANGE_FEEDBACK_PROPS,
} from '../../constants/security-keys-access-key-change.constants.cc';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import SecurityKeysAccessKeyChangeCCFooterContentHook from './hooks/security-keys-access-key-change-cc-footer-content.cc.hook';
// Styles
import {
  SecurityKeysAccessKeyChangeComponentCcSeparatorStyled,
  SecurityKeysAccessKeyChangeComponentCcStyled,
  SecurityKeysAccessKeyChangeComponentCcTextStyled,
} from './security-keys-access-key-change-cc-view.cc.styled';
// Translations
import translations from '../../translations/security-keys-access-key-change-card.translations.cc';

const SecurityKeysAccessKeyChangeyComponentsCcView = (): React.ReactElement => {
  const { profile } = AppContextConsumerHook();
  const { handleChangeAccessKeyInIvr } = SecurityKeysAccessKeyChangeCCFooterContentHook();

  return (
    <WizardFeedbackComponent
      {...ACCESS_KEY_CHANGE_FEEDBACK_PROPS}
      errorConfiguration={{
        ...ACCESS_KEY_CHANGE_FEEDBACK_PROPS.errorConfiguration,
        buttonClick: handleChangeAccessKeyInIvr,
      }}
    >
      <SecurityKeysAccessKeyChangeComponentCcStyled
        data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.containerId}
      >
        <TitleHeaderComponent testId={ACCESS_KEY_CHANGE_CONSTANTS_CC.titleId}>
          <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_TITLE} />
        </TitleHeaderComponent>
        <SecurityKeysAccessKeyChangeComponentCcSeparatorStyled>
          <ScriptBoxComponent>
            <SecurityKeysAccessKeyChangeComponentCcTextStyled>
              <ParagraphComponent bold>
                <FormattedMessageComponent
                  id={translations.CHANGE_ACCESS_KEY_CC_INITIAL_TEXT}
                  values={{ name: profile?.userDetails?.firstName }}
                />
              </ParagraphComponent>
              <ParagraphComponent testId={ACCESS_KEY_CHANGE_CONSTANTS_CC.firstTextId}>
                <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_FIRST_TEXT} />
              </ParagraphComponent>
              <ParagraphComponent testId={ACCESS_KEY_CHANGE_CONSTANTS_CC.secondTextId}>
                <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_SECOND_TEXT} />
              </ParagraphComponent>
              <SecurityKeysAccessKeyChangeCcConstraintsList />
              <SecurityKeysAccessKeyChangeComponentCcComponentText />
            </SecurityKeysAccessKeyChangeComponentCcTextStyled>
          </ScriptBoxComponent>
        </SecurityKeysAccessKeyChangeComponentCcSeparatorStyled>
        <AccessKeyChangeWizardStepFooterComponent />
      </SecurityKeysAccessKeyChangeComponentCcStyled>
    </WizardFeedbackComponent>
  );
};

export default SecurityKeysAccessKeyChangeyComponentsCcView;
