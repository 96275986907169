// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import SecurityKeysAccessKeyChangeSecondStepHook from '../hooks/security-keys-access-key-change-second-step.hook';
// Utilities
import { modifyAccessKeyChangeStep2NextButtonIsDisabled } from './utils/security-keys-access-key-change-second-step-actions.utils';
import { getWizardLinkConfiguration } from '../../../configurations/security-keys-access-key-change.configurations';

const AccessKeyStep2ActionsComponent = (): React.ReactElement => {
  const {
    newAccessKey,
    repeatNewAccessKey,
    inputError,
    accessKeyExpired,
    handleMakeUserLogoutEvent
  } = SecurityKeysAccessKeyChangeSecondStepHook();

  return (
    <WizardStepFooterComponent
      {...getWizardLinkConfiguration({ accessKeyExpired, handleMakeUserLogoutEvent })}
      rightButtonConfiguration={{
        disabled: modifyAccessKeyChangeStep2NextButtonIsDisabled({
          newAccessKey,
          repeatNewAccessKey,
          inputError,
        }),
      }}
    />
  );
};

export default AccessKeyStep2ActionsComponent;
