// Constants
import {
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  VALIDATION_ID,
} from './security-keys-access-key-change.constants.common';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  FEEDBACK_BUTTON,
  FEEDBACK_BUTTON_ERROR,
} from '../components/cc/translations/security-keys-access-key-change-feedback.translations.cc';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const ACCESS_KEY_CHANGE_CONSTANTS_CC = {
  containerId: 'access-key-change-cc-container',
  titleId: 'access-key-change-cc-title-section',
  constraintsId: 'access-key-change-cc-constaints-text',
  firstConstraintsId: 'access-key-change-cc-constaints-text-first',
  secondConstraintsId: 'access-key-change-cc-constaints-text-second',
  thirdConstraintsId: 'access-key-change-cc-constaints-text-third',
  fourthConstraintsId: 'access-key-change-cc-constaints-text-fourth',
  firstTextId: 'access-key-change-cc-first-text',
  secondTextId: 'access-key-change-cc-second-text',
  thirdTextId: 'access-key-change-cc-third-text',
  fourthTextId: 'access-key-change-cc-fourth-text',
};

const ACCESS_KEY_CHANGE_FEEDBACK_PROPS = {
  errorConfiguration: {
    description: FEEDBACK_ERROR_DESCRIPTION,
    title: FEEDBACK_ERROR_TITLE,
    linkLabel: FEEDBACK_BUTTON_ERROR,
    exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
  },
  successConfiguration: {
    description: FEEDBACK_SUCCESS_DESCRIPTION,
    linkLabel: FEEDBACK_BUTTON,
    title: FEEDBACK_SUCCESS_TITLE,
    exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
  },
};

export {
  ACCESS_KEY_CHANGE_CONSTANTS_CC,
  PASSWORD_MODULE_ASSET_ID,
  PASSWORD_MODULE_ASSET_COLOR,
  VALIDATION_ID,
  ACCESS_KEY_CHANGE_FEEDBACK_PROPS,
};
