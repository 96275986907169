// Constants
import {
  ICON_CONFIGURATION_SUCCESS,
  ICON_CONFIGURATION_ERROR,
  INPUT_LIMIT
} from './constants.ts/device-header-alias.constants';
// Enumerations
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Translations
import {
  SAVE,
  INPUT_LABEL,
  INPUT_HELPER_MESSAGE,
  MESSAGE_SUCCESS,
  MESSAGE_ERROR
} from '../translations/alias.translations';
// Types
import { AliasConfigurationReturnType } from './type/device-header-alias.configurations.return.type';
import { AliasConfigurationType } from './type/device-header-alias.configurations.props.type';
import { GetSnackBarConfigurationType } from './type/device-header-alias.snackbar.props.type';
import { GetSnackBarConfigurationReturnType } from './type/device-header-alias.snackbar.return.type';

const getIconConfiguration = ({ loading, success, error }) => {
  switch (true) {
    case !loading && success:
      return ICON_CONFIGURATION_SUCCESS;
    case !loading && error.icon:
      return ICON_CONFIGURATION_ERROR;
    default:
      return {};
  }
};

const getLinkConfiguration = ({ onClick, loading, success, error, formatMessage }) => {
  if (loading || success || error.description) {
    return {};
  }

  return {
    linkConfiguration: {
      onClick,
      children: formatMessage({ id: SAVE })
    }
  };
};

export const getInputAliasConfiguration = (
  { error, onChange, onClick, onFocus, loading, success, formatMessage }: AliasConfigurationType
): AliasConfigurationReturnType => ({
  fetching: loading,
  label: formatMessage({ id: INPUT_LABEL }),
  helperMessage: formatMessage({ id: INPUT_HELPER_MESSAGE }),
  limit: INPUT_LIMIT,
  limitCharsConfiguration: {
    error: Boolean(error?.description),
    limit: INPUT_LIMIT,
  },
  ...getIconConfiguration({ loading, success, error }),
  ...getLinkConfiguration({ error, onClick, loading, success, formatMessage }),
  onChange,
  onFocus,
  errorMessage: error.description,
});

export const getSnackBarConfiguration = (
  { type, onClose }: GetSnackBarConfigurationType
  ): GetSnackBarConfigurationReturnType => {
  return {
    [ToastTypesEnumeration.SUCCESS]: {
      type: ToastTypesEnumeration.SUCCESS,
      description: MESSAGE_SUCCESS,
      onClose
    },
    [ToastTypesEnumeration.ERROR]: {
      type: ToastTypesEnumeration.ERROR,
      description: MESSAGE_ERROR,
      onClose
    },
  }[type];
};