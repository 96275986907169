// Vendors
import React from 'react';
// Components
import {
  SectionDividerComponent,
  FetchErrorComponent,
  ErrorComponent
} from '@openbank/cb-ui-commons';
// Constants
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ERROR_NOT_FOUNT_PROPS } from './constants/device-sessions.constants';
// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';
// Styled
import { DeviceSessionsComponentStyled, DeviceSessionsEmptyComponentStyled } from './device-sessions.component-styled';
// Hooks
import { DeviceSessionsHook } from './hooks/device-sessions.hook';
// Configurations
import { getFetchErrorConfiguration } from '@/configurations/app.configurations';
import { getFetchDevicesErrorConfiguration, getSessionItemConfigurations } from './configutarions/device-sessions.configurations';
// Translations
import { HEADER, EMPTY_RESPONSE } from './translations/device-sessions.translations';
// Types
import { DeviceSessionsPropsType } from './types/device-sessions.component.props.type';

const DeviceSessionsComponent = ({
  deviceUUID
}: DeviceSessionsPropsType): React.ReactElement => {
  const {
    deviceSessions,
    fetching,
    formatDate,
    error,
    formatMessage,
    onRetry
  } = DeviceSessionsHook({ deviceUUID });

  return (
    <FetchErrorComponent fetching={fetching} error={getFetchErrorConfiguration(
      getFetchDevicesErrorConfiguration({
      error,
      formatMessage,
      onClick: onRetry
      }))
    }>
      <SectionDividerComponent
        type={SectionDividerTypesEnumeration.INFORMATIVE}
        title={ <FormattedMessageComponent id={HEADER} /> }
      />
      { deviceSessions?.length ? <>
        { deviceSessions.map(session => (
          <DeviceSessionsComponentStyled {...{ testId: session.sessionId }} key={session.sessionId}>
            { getSessionItemConfigurations({ formatDate, session, formatMessage }) }
          </DeviceSessionsComponentStyled>))
        }
      </> :
        <DeviceSessionsEmptyComponentStyled>
          <ErrorComponent {...{ ...ERROR_NOT_FOUNT_PROPS }}
            description={<FormattedMessageComponent id={EMPTY_RESPONSE} /> }
          />
        </DeviceSessionsEmptyComponentStyled>
      }
    </FetchErrorComponent>   
  );
};

export { DeviceSessionsComponent };