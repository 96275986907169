// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import SecurityKeysSignatureKeyChangeSecondStepHook from '../hooks/security-keys-signature-key-change-second-step.hook';
// Utilities
import { modifySignatureKeyChangeStep2NextButtonIsDisabled } from './utils/security-keys-signature-key-change-second-step-actions.utils';

const SecurityKeysSignatureKeyChangeSecondStepActionsComponent = (): React.ReactElement => {
  const { newSignatureKey, repeatNewSignatureKey, inputError } = SecurityKeysSignatureKeyChangeSecondStepHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: modifySignatureKeyChangeStep2NextButtonIsDisabled({
          newSignatureKey,
          repeatNewSignatureKey,
          inputError,
        }),
      }}
    />
  );
};

export default SecurityKeysSignatureKeyChangeSecondStepActionsComponent;
