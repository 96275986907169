// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import useRecoveryKeyStep5ActionsHook from './hooks/recovery-key-step5-actions.hook';
// Translations
import { CREATE_ACCESS_KEY } from './translations/recovery-key-step5-actions.translations';
// Utilities
import { isValidNewRecoveryKey } from '../../utils/recovery-key-step5.utils';

const RecoveryKeyStep5ActionsComponent = (): any => {
  const {
    fetching,
    recoveryKey,
    handleCreateRecoveryKeyButtonClick: nextClick,
  } = useRecoveryKeyStep5ActionsHook();

  return (
    <WizardStepFooterComponent
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: nextClick,
        disabled: !isValidNewRecoveryKey(recoveryKey) || fetching,
        loading: fetching,
        children: <FormattedMessageComponent id={CREATE_ACCESS_KEY} />,
      }}
    />
  );
};

export default RecoveryKeyStep5ActionsComponent;
