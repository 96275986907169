export default {
  REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_01_PROPS: {
    testId: 'request-signature-key-step1-message-01',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_02_PROPS: {
    testId: 'request-signature-key-step1-message-02',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_03_PROPS: {
    testId: 'request-signature-key-step1-message-03',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_ADDRESS_BOX_PROPS: {
    testId: 'request-signature-key-step1-address-box',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_ADDRESS_BOX_DESCRIPTION_PROPS: {
    testId: 'request-signature-key-step1-address-box-description',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_PROPS: {
    testId: 'request-signature-key-step1-trusted-message',
    type: 'informative',
  },
  REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_TITLE_PROPS: {
    testId: 'request-signature-key-step1-trusted-message-title',
    bold: true,
  },
  REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'request-signature-key-step1-trusted-message-description',
  },
};
