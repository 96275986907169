// Constants
import {
  PASSWORD_MODULE_ASSET_ID,
  PASSWORD_MODULE_ASSET_COLOR,
  VALIDATION_ID,
} from './security-keys-signature-key-change.constants.common';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_ERROR_TITLE,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE,
  SIGNATURE_KEY_FEEDBACK_BUTTON,
  SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR,
} from '../components/cc/translations/security-keys-signature-key-change-feedback.translations.cc';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const SIGNATURE_KEY_CHANGE_CONSTANTS_CC = {
  containerId: 'signature-key-change-cc-container',
  titleId: 'signature-key-change-cc-title-section',
  constraintsId: 'signature-key-change-cc-constaints-text',
  firstConstraintsId: 'signature-key-change-cc-constaints-text-first',
  secondConstraintsId: 'signature-key-change-cc-constaints-text-second',
  thirdConstraintsId: 'signature-key-change-cc-constaints-text-third',
  fourthConstraintsId: 'signature-key-change-cc-constaints-text-fourth',
  firstTextId: 'signature-key-change-cc-first-text',
  secondTextId: 'signature-key-change-cc-second-text',
  thirdTextId: 'signature-key-change-cc-third-text',
  fourthTextId: 'signature-key-change-cc-fourth-text',
};

const SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS = {
  errorConfiguration: {
    description: SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION,
    title: SIGNATURE_KEY_FEEDBACK_ERROR_TITLE,
    linkLabel: SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR,
    exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
  },
  successConfiguration: {
    description: SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION,
    linkLabel: SIGNATURE_KEY_FEEDBACK_BUTTON,
    title:SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE,
    exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
  },
};

export {
  SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS,
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  SIGNATURE_KEY_CHANGE_CONSTANTS_CC,
  VALIDATION_ID,
};
