// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import SecurityKeysAccessKeyChangeFirstStepHook from '../hooks/security-keys-access-key-change-first-step.hook';
// Utilities
import { modifyAccessKeyChangeStep1NextButtonIsDisabled } from './utils/security-keys-access-key-change-first-step-actions.utils';
// Configurations
import { getWizardLinkConfiguration } from '../../../configurations/security-keys-access-key-change.configurations';

const SecurityKeysAccessKeyChangeFirstStepActionsComponent = (): React.ReactElement => {
  const { accessKey, accessKeyExpired, handleMakeUserLogoutEvent } = SecurityKeysAccessKeyChangeFirstStepHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: modifyAccessKeyChangeStep1NextButtonIsDisabled({ accessKey }),
      }}
      {...getWizardLinkConfiguration({ accessKeyExpired, handleMakeUserLogoutEvent })}
    />
  );
};

export default SecurityKeysAccessKeyChangeFirstStepActionsComponent;
