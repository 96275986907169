export default {
  SECURITY_KEY_DEFAULT_CONSTRAINS_ENABLED: false,
  SECURITY_KEY_DEFAULT_GAPS: 8,
  SECURITY_KEY_DEFAULT_ASSET_ID: 'symbols_sign',
  SECURITY_KEY_DESCRIPTION_PROPS: {
    testId: 'security-key-label',
  },
  SECURITY_KEY_TITLE_PROPS: {
    testId: 'security-key-title',
    tag: 'h6',
  },
  WRAPPER_PROPS: {
    testId: 'security-key-wrapper',
  },
};
