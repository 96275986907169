export default {
  REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_01_PROPS: {
    testId: 'request-signature-key-step2-message-01',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_PROPS: {
    showIcon: false,
    testId: 'request-signature-key-step2-message',
    type: 'informative',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_REQUEST_RESUME_ITEM_PROPS: {
    testId: 'request-signature-key-step2-request',
  },
};
