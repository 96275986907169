// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import {
  SecurityKeysAccessKeyContainerLastTextCcStyled,
  SecurityKeysAccessKeyChangeComponentCcComponentTextStyled,
} from '../security-keys-access-key-change-cc-view.cc.styled';
// Constants
import { ACCESS_KEY_CHANGE_CONSTANTS_CC } from 'containers/security-keys/views/access-key-change/constants/security-keys-access-key-change.constants.cc';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Translations
import translations from 'containers/security-keys/views/access-key-change/translations/security-keys-access-key-change-card.translations.cc';

const SecurityKeysAccessKeyChangeComponentCcComponentText = (): React.ReactElement => {
  const { profile } = AppContextConsumerHook();

  return (
    <div>
      <SecurityKeysAccessKeyContainerLastTextCcStyled
        data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.thirdTextId}
      >
        <ParagraphComponent>
          <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_THIRD_TEXT} />
        </ParagraphComponent>
        <ParagraphComponent bold>
          <FormattedMessageComponent
            id={translations.CHANGE_ACCESS_KEY_CC_LAST_TEXT}
            values={{ name: profile?.userDetails?.firstName }}
          />
        </ParagraphComponent>
      </SecurityKeysAccessKeyContainerLastTextCcStyled>
      <SecurityKeysAccessKeyChangeComponentCcComponentTextStyled>
        <ParagraphComponent testId={ACCESS_KEY_CHANGE_CONSTANTS_CC.fourthTextId}>
          <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_FOURTH_TEXT} />
        </ParagraphComponent>
      </SecurityKeysAccessKeyChangeComponentCcComponentTextStyled>
    </div>
  );
};

export default SecurityKeysAccessKeyChangeComponentCcComponentText;
