// Vendors
import React from 'react';
// Constants
import { ACCESS_KEY_CHANGE_CONSTANTS_CC } from 'containers/security-keys/views/access-key-change/constants/security-keys-access-key-change.constants.cc';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import {
  SecurityKeysAccessKeyContainerCcListStyled,
  SecurityKeysAccessKeyContainerCcListItemStyled,
} from '../security-keys-access-key-change-cc-view.cc.styled';
// Translations
import translations from 'containers/security-keys/views/access-key-change/translations/security-keys-access-key-change-card.translations.cc';

const SecurityKeysAccessKeyChangeCcConstraintsList = (): React.ReactElement => (
  <SecurityKeysAccessKeyContainerCcListStyled
    data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.constraintsId}
  >
    <SecurityKeysAccessKeyContainerCcListItemStyled
      data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.firstConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_FIRST_CONSTRAINTS} />
      </ParagraphComponent>
    </SecurityKeysAccessKeyContainerCcListItemStyled>
    <SecurityKeysAccessKeyContainerCcListItemStyled
      data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.secondConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_SECOND_CONSTRAINTS} />
      </ParagraphComponent>
    </SecurityKeysAccessKeyContainerCcListItemStyled>
    <SecurityKeysAccessKeyContainerCcListItemStyled
      data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.thirdConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_THIRD_CONSTRAINTS} />
      </ParagraphComponent>
    </SecurityKeysAccessKeyContainerCcListItemStyled>
    <SecurityKeysAccessKeyContainerCcListItemStyled
      data-testid={ACCESS_KEY_CHANGE_CONSTANTS_CC.fourthConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.CHANGE_ACCESS_KEY_CC_FOURTH_CONSTRAINTS} />
      </ParagraphComponent>
    </SecurityKeysAccessKeyContainerCcListItemStyled>
  </SecurityKeysAccessKeyContainerCcListStyled>
);

export default SecurityKeysAccessKeyChangeCcConstraintsList;
