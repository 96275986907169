// Components
import RequestSignatureKeyStep1Component from '../../step1/request-signature-key-step1.component';
import RequestSignatureKeyStep2Component from '../../step2/request-signature-key-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import translations from '../translations/request-signature-key-wizard.translations';
import { TITLE as STEP1_TITLE } from '../../step1/translations/request-signature-key-step1.translations';
import { TITLE as STEP2_TITLE } from '../../step2/translations/request-signature-key-step2.translations';

const REQUEST_SIGNATURE_KEY_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: translations.ERROR_DESCRIPTION,
      title: translations.ERROR_TITLE,
      exitRoute: RoutesEnumeration.DASHBOARD,
    },
    successConfiguration: {
      description: translations.SUCCESS_DESCRIPTION,
      linkLabel: translations.GO_TO_DASHBOARD,
      title: translations.SUCCESS_TITLE,
      exitRoute: RoutesEnumeration.DASHBOARD,
    },
  },
  steps: [
    {
      component: RequestSignatureKeyStep1Component,
      title: STEP1_TITLE,
    },
    {
      component: RequestSignatureKeyStep2Component,
      title: STEP2_TITLE,
    },
  ],
};

export { REQUEST_SIGNATURE_KEY_WIZARD_PROPS };
