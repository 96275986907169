// Vendors
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Handlers
import SecurityKeysAccessKeyChangeCCFooterHandlers from '../handlers/security-keys-access-key-change-cc-footer-content.cc.handlers';
// Types
import { SecurityKeysAccessKeyChangeCCFooterContentHookType } from './types/security-keys-access-key-change-cc-footer-content.cc.hook.type';

const SecurityKeysAccessKeyChangeCCFooterContentHook =
  (): SecurityKeysAccessKeyChangeCCFooterContentHookType => {
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const [isLoadingIvr, setIsLoadingIvr] = useState<boolean>(false);

    return {
      ...SecurityKeysAccessKeyChangeCCFooterHandlers({
        setIsLoadingIvr,
        setResult,
      }),
      isLoadingIvr,
    };
  };

export default SecurityKeysAccessKeyChangeCCFooterContentHook;
