// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import {
  useRecoveryKeyContextConsumerHook,
  useRecoveryOptionContextConsumerHook,
} from 'containers/recovery-key/contexts/recovery-key.context';
import { useDrPicassoManagerContextConsumerHook } from 'contexts/dr-picasso-manager.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import RecoveryKeyStep3TrackingHandlers from '../components/step3/handlers/recovery-key-step3.tracking.handlers';
// Types
import { RecoveryKeyHandlers } from '../handlers/recovery-key.handlers';
import { useRecoveryKeyInterface, useRecoveryKeyParams } from './types/recovery-key.hook.type';
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
// Hooks
import { useRecoveryKeyStepChallenge } from './recovery-key-step-challenge.hook';
import useTimer from 'hooks/use-timer.hook';
// Enums
import { ChallengeWrapperTypesEnumeration } from 'components/challenge-wrapper/configurations/enumerations/challenge-wrapper-types.enumeration';
// Resolvers
import {
  validateRecoveryKeyDataOtpResolver,
  validateSignatureKeyResolver,
} from '../resolvers/recovery-key.resolvers';
// Utils
import { getTimerLabelTranslationId } from 'components/challenge-wrapper/hooks/utils/challenge-wrapper.hook.utils';
// Constants
import { TIMER_COUNTER } from '../constants/recovery-key.constants';
import { getNextRecoveryKeyStep } from './utils/recovery-key.hook.utils';

const useRecoveryKey = ({ nextStep, challenge }: useRecoveryKeyParams): useRecoveryKeyInterface => {
  const { formatMessage } = useCbIntl();
  const { authData, fetching, email, prefix, phoneNumber, setAuthData } =
    useRecoveryKeyContextConsumerHook();
  const { recoveryOption, setRecoveryOption } = useRecoveryOptionContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { clientData } = useDrPicassoManagerContextConsumerHook();

  const { handleRecoveryOptionItemRadioClickTracking } = AppTrackingHook(
    RecoveryKeyStep3TrackingHandlers
  );
  const { signatureKeyPositions: positions = [], passwordRecoveryOptions } =
    (authData as PasswordRecoveryOptionsApiResponseType) || {};
  const { timer, isTimerActive, handleRestart } = useTimer(TIMER_COUNTER);
  const timerLabelTranslationId = getTimerLabelTranslationId(timer);
  const { setCurrentStep } = useWizardContextConsumerHook();

  const {
    otpValue: value,
    challenged,
    setOtpValue: onChangeChallenge,
    handleVerifyRecoveryKeyDataButtonClick: handleSendChallenge,
    handleManageNextRecoveryStepButtonClick: handleValidateChallenge,
  } = useRecoveryKeyStepChallenge({
    nextStep:
      challenge === ChallengeWrapperTypesEnumeration.OTP
        ? passwordRecoveryOptions && getNextRecoveryKeyStep(passwordRecoveryOptions)
        : nextStep,
    resolver:
      challenge === ChallengeWrapperTypesEnumeration.OTP
        ? validateRecoveryKeyDataOtpResolver
        : validateSignatureKeyResolver,
  });

  const { handleSetRecoveryOption, handleResendOtp, handleInitiateOtp } = RecoveryKeyHandlers({
    setRecoveryOption,
    handleRecoveryOptionItemRadioClickTracking,
    email,
    handleRestart,
    phoneNumber,
    prefix,
    setAuthData,
    setResult,
    setCurrentStep,
  });

  return {
    handleSetRecoveryOption,
    handleValidateChallenge,
    onChangeChallenge,
    handleSendChallenge,
    handleResendOtp,
    formatMessage,
    handleInitiateOtp,
    clientData,
    challenged,
    recoveryOption,
    value,
    positions,
    fetching,
    timer,
    isTimerActive,
    timerLabelTranslationId,
  };
};

export { useRecoveryKey };
