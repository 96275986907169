import {
  checkNoAscendingDescendingSequences,
  checkNoUse4EqualsNumbers,
} from 'utils/access-key/access-key-constraints-checkers.utils';

const recoveryKeyHasCorrectLength = (recoveryKey: string): boolean => recoveryKey.length === 4;

const isValidNewRecoveryKey = (recoveryKey: string): boolean =>
  recoveryKeyHasCorrectLength(recoveryKey) &&
  checkNoAscendingDescendingSequences(recoveryKey) &&
  checkNoUse4EqualsNumbers(recoveryKey);

export { isValidNewRecoveryKey, recoveryKeyHasCorrectLength };
