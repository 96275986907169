// Enums
import { DeviceModalEnum } from '../../device-modal/enumerations/device-modal.enumeration';
// Translations
import { SNACKBAR_MESSAGES } from '../translations/device-info.translations';

export const ASSET_PROPS = {
  id: 'info',
  sizes: { width: 16, height: 16 },
  colors: {
    fill: 'linkPrimaryA',
  },
};

export const BUTTON_TERTIARY_PROPS = { type: 'tertiary' };

export const MESSAGES_SNACKBAR = {
  ERROR: {
    [DeviceModalEnum.UNROLL_TRUSTED]: SNACKBAR_MESSAGES.ERROR.UNROLL_TRUSTED,
    [DeviceModalEnum.BLOCK]: SNACKBAR_MESSAGES.ERROR.BLOCK,
    [DeviceModalEnum.UNBLOCK]: SNACKBAR_MESSAGES.ERROR.UNBLOCK,
  },
  SUCCESS: {
    [DeviceModalEnum.UNROLL_TRUSTED]: SNACKBAR_MESSAGES.SUCCESS.UNROLL_TRUSTED,
    [DeviceModalEnum.BLOCK]: SNACKBAR_MESSAGES.SUCCESS.BLOCK,
    [DeviceModalEnum.UNBLOCK]: SNACKBAR_MESSAGES.SUCCESS.UNBLOCK,
  },
};
