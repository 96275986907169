// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import {
  SignatureKeyContainerLastTextCcStyled,
  SignatureKeyTextCcStyled,
} from '../security-keys-signature-key-change.content.styled.cc';
// Constants
import { SIGNATURE_KEY_CHANGE_CONSTANTS_CC } from 'containers/security-keys/views/signature-key-change/constants/security-keys-signature-key-change.constants.cc';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Translations
import {
  CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT,
  CHANGE_SIGNATURE_KEY_CC_LAST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT,
} from 'containers/security-keys/views/signature-key-change/translations/security-keys-signature-key-change-card.translations.cc';

const SecurityKeysSignatureKeyChangeCcFinalText = (): React.ReactElement => {
  const { profile } = AppContextConsumerHook();

  return (
    <div>
      <SignatureKeyContainerLastTextCcStyled
        data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.thirdTextId}
      >
        <ParagraphComponent>
          <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT} />
        </ParagraphComponent>
        <ParagraphComponent bold>
          <FormattedMessageComponent
            id={CHANGE_SIGNATURE_KEY_CC_LAST_TEXT}
            values={{ name: profile?.userDetails?.firstName }}
          />
        </ParagraphComponent>
      </SignatureKeyContainerLastTextCcStyled>
      <SignatureKeyTextCcStyled>
        <ParagraphComponent testId={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.fourthTextId}>
          <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT} />
        </ParagraphComponent>
      </SignatureKeyTextCcStyled>
    </div>
  );
};

export default SecurityKeysSignatureKeyChangeCcFinalText;
