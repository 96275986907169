// Types
import { RecoveryKeyStep1NExtButtonIsDisabledType } from './types/recovery-key-step1-actions.utils.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';
import { isValidPhoneNumber } from 'utils/phone.utils';

const recoveryKeyStep1NextButtonIsDisabled = ({
  fetching,
  email,
  prefix,
  phoneNumber,
}: RecoveryKeyStep1NExtButtonIsDisabledType): boolean =>
  Boolean(fetching || !isValidEmail(email) || !isValidPhoneNumber({ prefix, phoneNumber }));

export { recoveryKeyStep1NextButtonIsDisabled };
