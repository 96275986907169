const DOMAIN = 'cobrandedCC.services.userSettings.recoveryAccess.';

const FIRST_STEP_TITLE = `${DOMAIN}firstStep.title`,
  FIRST_STEP_DESCRIPTION = `${DOMAIN}firstStep.description`,
  FIRST_STEP_ITEM_1 = `${DOMAIN}firstStep.radioItem.first`,
  FIRST_STEP_ITEM_2 = `${DOMAIN}firstStep.radioItem.second`,
  FIRST_STEP_ITEM_3 = `${DOMAIN}firstStep.radioItem.third`,
  FIRST_STEP_ITEM_4 = `${DOMAIN}firstStep.radioItem.forth`,
  SIDEBAR_TITLE = `${DOMAIN}sidebar.title`,
  SIDEBAR_DESCRIPTION = `${DOMAIN}sidebar.description`,
  SIDEBAR_ACCESS_SMS_TITLE = `${DOMAIN}sidebarAccessSMS.title`,
  SIDEBAR_ACCESS_SMS_BUTTON = `${DOMAIN}sidebarAccessSMS.button`,
  CLIENT_NAME = `${DOMAIN}subtitle.clientName`,
  PHONE_CONFIRMATION = `${DOMAIN}description.phoneConfirmation`,
  FIRST_OPTION_TITLE = `${DOMAIN}secondStep.firstOption.title`,
  FIRST_OPTION_BOX2_P1 = `${DOMAIN}secondStep.firstOption.secondBox.firstDescription`,
  FIRST_OPTION_BOX2_L1_I1 = `${DOMAIN}secondStep.firstOption.secondBox.firstList.firstItem`,
  FIRST_OPTION_BOX2_L1_I2 = `${DOMAIN}secondStep.firstOption.secondBox.firstList.secondItem`,
  FIRST_OPTION_BOX2_P2 = `${DOMAIN}secondStep.firstOption.secondBox.secondDescription`,
  FIRST_OPTION_BOX2_L2_I1 = `${DOMAIN}secondStep.firstOption.secondBox.secondList.firstItem`,
  FIRST_OPTION_BOX2_L2_I2 = `${DOMAIN}secondStep.firstOption.secondBox.secondList.secondItem`,
  FIRST_OPTION_MESSAGE = `${DOMAIN}secondStep.firstOption.message`,
  ACCESS_SMS_BOX1_TITLE = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.firstBox.title`,
  ACCESS_SMS_BOX1_DESCRIPTION = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.firstBox.description`,
  ACCESS_SMS_BOX2_DESCRIPTION = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.secondBox.description`,
  ACCESS_SMS_BOX2_MESSAGE = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.secondBox.message`,
  ACCESS_SMS_INPUT_EMAIL = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.clientEmail`,
  ACCESS_SMS_INPUT_PHONE = `${DOMAIN}secondStep.firstOption.accessSMS.firstStep.clientPhoneNumber`,
  ACCESS_SMS_INPUT_PHONE_ERROR = `${DOMAIN}secondStep.firstOption.accessSMS.firsStep.clientPhoneNumber.error`,
  ACCESS_SMS_SIGNATURE_TITLE =
    'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step4.signatureKey.title',
  ACCESS_SMS_SIGNATURE_DESCRIPTION = `${DOMAIN}secondStep.firstOption.accessSMS.secondStep.description`,
  ACCESS_SMS_OTP_TITLE = `${DOMAIN}secondStep.firstOption.accessSMS.secondStep.title`,
  ACCESS_SMS_OTP_DESCRIPTION = `${DOMAIN}secondStep.firstOption.accessSMS.secondStep.description`,
  SECOND_OPTION_TITLE = `${DOMAIN}secondStep.secondOption.title`,
  SECOND_OPTION_BOX2_P1 = `${DOMAIN}secondStep.secondOption.secondBox.firstDescription`,
  SECOND_OPTION_BOX2_P2 = `${DOMAIN}secondStep.secondOption.secondBox.secondDescription`,
  SECOND_OPTION_BOX2_P3 = `${DOMAIN}secondStep.secondOption.secondBox.thirdDescription`,
  MANDATORY_FILES = `${DOMAIN}secondStep.mandatoryFiles`,
  THIRD_OPTION_TITLE = `${DOMAIN}secondStep.thirdOption.title`,
  FILES_SENT_TO_EMAIL = `${DOMAIN}secondStep.thirdOption.firstBox.secondDescription`,
  AFTER_RECEIVING_FILES = `${DOMAIN}secondStep.afterReceivingFiles`,
  UPDATE_ACCESS_KEY = `${DOMAIN}secondStep.updateAccessKey`,
  THIRD_OTION_B2_P1 = `${DOMAIN}secondStep.thirdOption.secondBox.firstDescription`,
  THIRD_OPTION_BOX1_L1_I1 = `${DOMAIN}secondStep.forthOption.firstBox.firstList.firstItem`,
  THIRD_OPTION_BOX1_L1_I2 = `${DOMAIN}secondStep.forthOption.firstBox.firstList.secondItem`,
  SAY_YOUR_ADDRESS = `${DOMAIN}secondStep.sayYourAddress`,
  READING_WARNING = `${DOMAIN}secondStep.readingWarning`,
  FOURTH_OPTION_TITLE = `${DOMAIN}secondStep.forthOption.title`,
  FOURTH_OPTION_BOX1_L1_I1 = `${DOMAIN}secondStep.thirdOption.firstBox.firstList.firstItem`,
  FOURTH_OPTION_BOX1_L1_I2 = `${DOMAIN}secondStep.thirdOption.firstBox.firstList.secondItem`,
  FOURTH_OPTION_BOX1_L1_I3 = `${DOMAIN}secondStep.forthOption.firstBox.firstList.thirdItem`,
  ONCE_RECEIVING_FILES = `${DOMAIN}secondStep.onceReceivingFiles`,
  PAGE_TITLE = 'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.pageTitle';

export {};

export {
  PAGE_TITLE,
  FIRST_STEP_TITLE,
  FIRST_STEP_DESCRIPTION,
  FIRST_STEP_ITEM_1,
  FIRST_STEP_ITEM_2,
  FIRST_STEP_ITEM_3,
  FIRST_STEP_ITEM_4,
  SIDEBAR_TITLE,
  SIDEBAR_DESCRIPTION,
  SIDEBAR_ACCESS_SMS_TITLE,
  SIDEBAR_ACCESS_SMS_BUTTON,
  CLIENT_NAME,
  PHONE_CONFIRMATION,
  FIRST_OPTION_TITLE,
  FIRST_OPTION_BOX2_P1,
  FIRST_OPTION_BOX2_L1_I1,
  FIRST_OPTION_BOX2_L1_I2,
  FIRST_OPTION_BOX2_P2,
  FIRST_OPTION_BOX2_L2_I1,
  FIRST_OPTION_BOX2_L2_I2,
  FIRST_OPTION_MESSAGE,
  ACCESS_SMS_BOX1_TITLE,
  ACCESS_SMS_BOX1_DESCRIPTION,
  ACCESS_SMS_BOX2_DESCRIPTION,
  ACCESS_SMS_BOX2_MESSAGE,
  ACCESS_SMS_INPUT_EMAIL,
  ACCESS_SMS_INPUT_PHONE,
  ACCESS_SMS_INPUT_PHONE_ERROR,
  ACCESS_SMS_SIGNATURE_TITLE,
  ACCESS_SMS_SIGNATURE_DESCRIPTION,
  ACCESS_SMS_OTP_TITLE,
  ACCESS_SMS_OTP_DESCRIPTION,
  SECOND_OPTION_TITLE,
  SECOND_OPTION_BOX2_P1,
  SECOND_OPTION_BOX2_P2,
  SECOND_OPTION_BOX2_P3,
  THIRD_OPTION_TITLE,
  FOURTH_OPTION_TITLE,
  FOURTH_OPTION_BOX1_L1_I1,
  FOURTH_OPTION_BOX1_L1_I2,
  FOURTH_OPTION_BOX1_L1_I3,
  MANDATORY_FILES,
  THIRD_OPTION_BOX1_L1_I1,
  THIRD_OPTION_BOX1_L1_I2,
  FILES_SENT_TO_EMAIL,
  AFTER_RECEIVING_FILES,
  UPDATE_ACCESS_KEY,
  THIRD_OTION_B2_P1,
  SAY_YOUR_ADDRESS,
  READING_WARNING,
  ONCE_RECEIVING_FILES,
};
