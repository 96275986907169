const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.cbSidebar.';

const DESCRIPTION_TITLE_01 = `${DOMAIN}descriptionTitle01`,
  TITLE = `${DOMAIN}title`,
  DESCRIPTION_01 = `${DOMAIN}description01`,
  DESCRIPTION_TITLE_02 = `${DOMAIN}descriptionTitle02`,
  DESCRIPTION_02 = `${DOMAIN}description02`;

export { DESCRIPTION_TITLE_01, DESCRIPTION_TITLE_02, TITLE, DESCRIPTION_01, DESCRIPTION_02 };
