// Vendors
import React from 'react';
// Constants
import { SIGNATURE_KEY_CHANGE_CONSTANTS_CC } from 'containers/security-keys/views/signature-key-change/constants/security-keys-signature-key-change.constants.cc';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import {
  SignatureKeyContainerCcListStyled,
  SignatureKeyContainerCcListItemStyled,
} from '../security-keys-signature-key-change.content.styled.cc';
// Translations
import {
  CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_FOURTH_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS,
} from 'containers/security-keys/views/signature-key-change/translations/security-keys-signature-key-change-card.translations.cc';

const SecurityKeysSignatureKeyChangeCcConstraintsList = (): React.ReactElement => (
  <SignatureKeyContainerCcListStyled data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.constraintsId}>
    <SignatureKeyContainerCcListItemStyled
      data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.firstConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS} />
      </ParagraphComponent>
    </SignatureKeyContainerCcListItemStyled>
    <SignatureKeyContainerCcListItemStyled
      data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.secondConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS} />
      </ParagraphComponent>
    </SignatureKeyContainerCcListItemStyled>
    <SignatureKeyContainerCcListItemStyled
      data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.thirdConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS} />
      </ParagraphComponent>
    </SignatureKeyContainerCcListItemStyled>
    <SignatureKeyContainerCcListItemStyled
      data-testid={SIGNATURE_KEY_CHANGE_CONSTANTS_CC.fourthConstraintsId}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={CHANGE_SIGNATURE_KEY_CC_FOURTH_CONSTRAINTS} />
      </ParagraphComponent>
    </SignatureKeyContainerCcListItemStyled>
  </SignatureKeyContainerCcListStyled>
);

export default SecurityKeysSignatureKeyChangeCcConstraintsList;
