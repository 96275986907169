// Vendors
import styled from 'styled-components';
// Utils
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

const SecurityKeysAccessKeyChangeLoaderComponentCcStyled = styled.section.attrs(
  ({ testId }: { testId?: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl};

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM};
  }
`;

export { SecurityKeysAccessKeyChangeLoaderComponentCcStyled };
