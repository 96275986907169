// Vendors
import styled from 'styled-components';
// Utils
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

const SecurityKeysAccessKeyChangeComponentCcStyled = styled.section.attrs(
  ({ testId }: { testId?: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl};
  width: 100%;
  position: relative;

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM};
  }
`;

SecurityKeysAccessKeyChangeComponentCcStyled.displayName =
  'SecurityKeysAccessKeyChangeComponentCcStyled';

const SecurityKeysAccessKeyContainerCcListStyled = styled.ul.attrs(
  ({ testId }: { testId?: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
`;

SecurityKeysAccessKeyContainerCcListStyled.displayName =
  'SecurityKeysAccessKeyContainerCcListStyled';

const SecurityKeysAccessKeyContainerCcListItemStyled = styled.li.attrs(
  ({ testId }: { testId?: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  padding: 0;
  margin: 0;
`;

SecurityKeysAccessKeyContainerCcListItemStyled.displayName =
  'SecurityKeysAccessKeyContainerCcListItemStyled';

const SecurityKeysAccessKeyChangeComponentCcTextStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

SecurityKeysAccessKeyChangeComponentCcTextStyled.displayName =
  'SecurityKeysAccessKeyChangeComponentCcTextStyled';

const SecurityKeysAccessKeyContainerLastTextCcStyled = styled.div.attrs(
  ({ testId }: { testId?: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

SecurityKeysAccessKeyContainerLastTextCcStyled.displayName =
  'SecurityKeysAccessKeyContainerLastTextCcStyled';

const SecurityKeysAccessKeyChangeComponentCcComponentTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.linkCallCenterC};
  padding-top: ${({ theme }) => theme.spaces.spacingXxxs};
`;

SecurityKeysAccessKeyChangeComponentCcComponentTextStyled.displayName =
  'SecurityKeysAccessKeyChangeComponentCcComponentTextStyled';

const SecurityKeysAccessKeyChangeComponentCcSeparatorStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingM} 0 ${({ theme }) => theme.spaces.spacingM};
`;

SecurityKeysAccessKeyChangeComponentCcSeparatorStyled.displayName =
  'SecurityKeysAccessKeyChangeComponentCcSeparatorStyled';

export {
  SecurityKeysAccessKeyChangeComponentCcComponentTextStyled,
  SecurityKeysAccessKeyChangeComponentCcSeparatorStyled,
  SecurityKeysAccessKeyChangeComponentCcStyled,
  SecurityKeysAccessKeyChangeComponentCcTextStyled,
  SecurityKeysAccessKeyContainerCcListItemStyled,
  SecurityKeysAccessKeyContainerCcListStyled,
  SecurityKeysAccessKeyContainerLastTextCcStyled,
};
