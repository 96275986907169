// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  MessageComponent,
  SelectorCardComponent,
} from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/request-signature-key-step1.constants';
// Hooks
import { RequestSignatureKeyStep1Hook } from './hooks/request-signature-key-step1.hook';
// Styles
import { RequestSignatureKeyContainerStyled } from './request-signature-key-step1.component.styled';
// Translations
import {
  ADDRESS_BOX_TITLE,
  MESSAGE_01,
  MESSAGE_02,
  MESSAGE_03,
  TRUSTED_DESCRIPTION,
  TRUSTED_TITLE,
} from './translations/request-signature-key-step1.translations';
// Utils
import { createPostalAddress } from './utils/request-signature-key-step1.utils';

const RequestSignatureKeyStep1Component = (): React.ReactElement => {
  const { address, formatMessage, hasTrustedDevice, handleClickNextButton } =
    RequestSignatureKeyStep1Hook();

  return (
    <RequestSignatureKeyContainerStyled>
      {hasTrustedDevice && (
        <MessageComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_PROPS}>
          <ParagraphComponent
            {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_TITLE_PROPS}
          >
            <FormattedMessageComponent id={TRUSTED_TITLE} />
          </ParagraphComponent>
          <ParagraphComponent
            {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_DESCRIPTION_PROPS}
          >
            <FormattedMessageComponent id={TRUSTED_DESCRIPTION} />
          </ParagraphComponent>
        </MessageComponent>
      )}
      <div>
        <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_01_PROPS}>
          <FormattedMessageComponent id={MESSAGE_01} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_02_PROPS}>
          <FormattedMessageComponent id={MESSAGE_02} />
        </ParagraphComponent>
      </div>
      <SelectorCardComponent
        {...constants.REQUEST_SIGNATURE_KEY_STEP_1_ADDRESS_BOX_PROPS}
        selectorCardList={[
          {
            title: <FormattedMessageComponent id={ADDRESS_BOX_TITLE} />,
            children: (
              <ParagraphComponent
                {...constants.REQUEST_SIGNATURE_KEY_STEP_1_ADDRESS_BOX_DESCRIPTION_PROPS}
              >
                {address &&
                  createPostalAddress({ address, formatMessage }).map((item, index) => (
                    <React.Fragment key={`${item}-${index}`}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))}
              </ParagraphComponent>
            ),
          },
        ]}
      />
      <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_03_PROPS}>
        <FormattedMessageComponent id={MESSAGE_03} />
      </ParagraphComponent>
      <WizardStepFooterComponent rightButtonConfiguration={{ onClick: handleClickNextButton }} />
    </RequestSignatureKeyContainerStyled>
  );
};

export default RequestSignatureKeyStep1Component;
