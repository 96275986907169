// Types
import {
  RecoveryKeyStep2BuilderHandlersType,
  RecoveryKeyStep2BuilderReturnHandlersType,
} from './types/recovery-key-step-challenge.handlers.type';
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';

const verifyRecoveryKeyDataButtonClickHandler = async ({
  pin,
  last4DigitsPan,
  authData,
  handleVerifyRecoveryKeyDataButtonClickTracking,
  resolver,
  setAuthData,
  setChallenged,
  setResult,
  setFetching,
  otpValue,
}: Omit<RecoveryKeyStep2BuilderHandlersType, 'setCurrentStep' | 'nextStep'>): Promise<void> => {
  setFetching(true);
  handleVerifyRecoveryKeyDataButtonClickTracking();

  const [response, error] = await resolver({
    authData,
    otpValue,
    pin,
    last4DigitsPan,
  });

  if (error) {
    setResult(false);
  } else {
    setAuthData(response as PasswordRecoveryOptionsApiResponseType);
    await setChallenged(true);
    setFetching(false);
  }
};

const manageNextRecoveryStepButtonClickHandler = ({
  nextStep,
  setCurrentStep,
  setResult,
}: Pick<
  RecoveryKeyStep2BuilderHandlersType,
  'nextStep' | 'setCurrentStep' | 'setResult'
>): void => {
  nextStep ? setCurrentStep(nextStep) : setResult(true);
};

const RecoveryKeyStepChallengeHandlers = ({
  pin,
  last4DigitsPan,
  authData,
  handleVerifyRecoveryKeyDataButtonClickTracking,
  nextStep,
  setAuthData,
  setChallenged,
  setCurrentStep,
  setFetching,
  setResult,
  resolver,
  otpValue,
}: RecoveryKeyStep2BuilderHandlersType): RecoveryKeyStep2BuilderReturnHandlersType => ({
  handleManageNextRecoveryStepButtonClick: () =>
    manageNextRecoveryStepButtonClickHandler({
      setResult,
      nextStep,
      setCurrentStep,
    }),
  handleVerifyRecoveryKeyDataButtonClick: () =>
    verifyRecoveryKeyDataButtonClickHandler({
      pin,
      last4DigitsPan,
      authData,
      handleVerifyRecoveryKeyDataButtonClickTracking,
      setAuthData,
      setChallenged,
      setFetching,
      setResult,
      resolver,
      otpValue,
    }),
});

export default RecoveryKeyStepChallengeHandlers;
