// Vendors
import { Usd } from '@openbank/cf-ui-framework';
// Constants
import {
  SIGNATURE_KEY_IVR_INTERVAL_TIME,
  SIGNATURE_KEY_IVR_TIME_OUT,
  SIGNATURE_KEY_IVR_SUCCESS_RESULT,
} from './constants/security-keys-signature-key-change-cc-footer-content.handlers.constants.cc';
// Types
import { SignatureKeyFooterContentCCHandlersReturnHandlersType } from './types/security-keys-signature-key-change-footer-content.cc.handlers.return.type';
import { CancelClickSignatureKeyCCHandlersType } from './types/security-keys-signature-key-change-footer-content.cc.handlers.type';
import { ChangSignatureKeyInIvrHandlerType } from './types/change-signature-key-in-ivr.handlers.type';

const changeSignatureKeyInIvr = ({
  setResult,
  setIsLoadingIvr,
}: ChangSignatureKeyInIvrHandlerType): void => {
  setIsLoadingIvr(true);

  Usd()?.changeSignatureKeyInIvr();

  const startTime = Date.now();

  const intervalId = setInterval(() => {
    if (Usd()?.getSignatureKeyChanged()) {
      const IVRResult = Usd()?.getSignatureKeyChanged();

      clearInterval(intervalId);
      setIsLoadingIvr(false);
      setResult(IVRResult === SIGNATURE_KEY_IVR_SUCCESS_RESULT);
    } else if (Date.now() - startTime > SIGNATURE_KEY_IVR_TIME_OUT) {
      clearInterval(intervalId);
      setIsLoadingIvr(false);
      setResult(false);
    }
  }, SIGNATURE_KEY_IVR_INTERVAL_TIME);
};

const SecurityKeysSignatureKeyChangeContentCCHandlers = ({
  setIsLoadingIvr,
  setResult,
}: CancelClickSignatureKeyCCHandlersType): SignatureKeyFooterContentCCHandlersReturnHandlersType => ({
  handleChangeSignatureKeyInIvr: () => changeSignatureKeyInIvr({ setResult, setIsLoadingIvr }),
});

export default SecurityKeysSignatureKeyChangeContentCCHandlers;
