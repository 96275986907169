// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Types
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
import { RecoveryKeyStepResolverBuilderType } from 'containers/recovery-key/types/recovery-key-step-resolver-builder.type';
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';
// Utilities
import { getDeviceLoginParams } from '@openbank/cf-ui-framework';
import { SssDocumentTypesEnum, StorageCookiesEnum } from '@openbank/cf-ui-static-data';
import { ENDPOINTS } from '../constants/recovery-key.constants.common';
import { cookies } from '@openbank/cf-ui-architecture-core';

const validateSignatureKeyService = async ({
  authData: { progressId, signatureKeyPositions: positions },
  otpValue,
}: RecoveryKeyStepResolverBuilderType): Promise<
  [PasswordRecoveryOptionsApiResponseType | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    endpoint: ENDPOINTS.VERIFY_SIGNATURE_KEY,
    customHeaders: { progressId, accessToken: cookies.getCookie(StorageCookiesEnum.AGENT_TOKEN) },
    params: {
      positions,
      key: otpValue,
    },
  });

const validateRecoveryKeyDataOtpService = async ({
  authData: { progressId, otpId },
  otpValue,
}: RecoveryKeyStepResolverBuilderType): Promise<
  [PasswordRecoveryOptionsApiResponseType | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    endpoint: ENDPOINTS.VERIFY_OTP,
    customHeaders: { progressId, accessToken: cookies.getCookie(StorageCookiesEnum.AGENT_TOKEN) },
    params: { otpId, otpValue },
  });

const initRecoveryKeyService = async (props: {
  username: string;
  phoneNumber: string;
}): Promise<[ChallengeSssApiResponseType | Record<string, any>, Response]> =>
  apiUtilsCb.post({
    endpoint: ENDPOINTS.OTP_INITIALIZATION,
    customHeaders: { accessToken: cookies.getCookie(StorageCookiesEnum.AGENT_TOKEN) },
    params: {
      documentType: SssDocumentTypesEnum.EMAIL,
      ...getDeviceLoginParams({}),
      ...props,
    },
  });

export { validateSignatureKeyService, validateRecoveryKeyDataOtpService, initRecoveryKeyService };
